
export default {
  lang: 'hu',
  theme: 'bolive',
  consentMode: 'soft-opt-in', // opt-in || opt-out || soft-opt-in
  softOptInCategory: 'marketing',
  initialCategory: 'marketing',
  revalidationFrom: 1550769460127,
  decisionCookieName: 'dwcconsent',
  decisionCookieMaxAge: 365 * 24 * 60 * 60,
  decisionCookieDomain: null,
  placement: 'bottomcover', // topcover, bottomcover
  editConsentSelector: '.show-dwcc-cookie-settings',
  errorCookiesNotCreated: 'Unable to save your decision on the use of cookies. Your browser does not allow cookies to be created.',
  cookieTableMap: {
    pattern: '{{i18n.table_name}}',
    purpose: '{{i18n.table_purpose}}',
    expiry: '{{i18n.table_expiry}}',
    source: '{{i18n.table_source}}',
    third_party: '{{i18n.table_thirdparty}}',
    domain: '{{i18n.table_domain}}',
    type: '{{i18n.table_type}}'
  },
  policyGroupTableMap: {
    provider: '{{i18n.table_provider}}',
    policy_group: '{{i18n.table_cookies}}',
    description: false,
    links: {
      content: '{{i18n.table_link}}',
      data_policy: '{{i18n.table_link_data_policy}}',
      cookie_policy: '{{i18n.table_link_cookie_policy}}',
      opt_out: '{{i18n.table_link_opt_out}}'
    }
  },


  policyGroups: [
    {
      policy_group: 'google',
      provider: `Google <small>Google Inc.,1600 Amphitheatre Parkway Mountain View, CA 94043, USA</small>:
        (Doubleclick Bid Manager,
        DoubleClick Floodlight, Google Adwords, Google Dynamic Remarketing,
        Google Conversion Tag, Google Analytics)
      `,
      description: {
        i18n: {
          hu: (
            `
            <dl>
              <dt>
                <a href="https://support.google.com/searchads/answer/9015629?hl=hu" target="_blank">DoubleClick Bid Manager</a> |
                <a href="https://support.google.com/searchads/answer/2404957?hl=hu" target="_blank">DoubleClick Floodlight</a>
              </dt>
              <dd>
                A DoubleClick-hirdetői termékek és a Google Analytics 360 Suite-termékek 2018. év folyamán egyetlen márkanév, a Google Marketing Platform alatt lettek egyesítve.
              </dd>

              <dt>
                <a href="https://marketingplatform.google.com/about/" target="_blank">Google Marketing Platform</a>
              </dt>
              <dd>
                Google Marketing Platform, egy egyesített online hirdetési és analitikai szolgáltatásokat magában foglaló eszközcsoport, amely hatékonyabb marketinget és jobb eredményeket ígér a Google korábbiakban szolgáltatott marketing eszközeihez képest.
              </dd>

              <dt>
                <a href="https://ads.google.com/home/how-it-works/" target="_blank">Google Ads</a>
              </dt>
              <dd>
                Google Ads (korábban Google AdWords) egy a Google által fejlesztett online hirdetési platform, amelyen keresztül a hirdetők fizetnek azért, hogy értékesítésre ösztönző hirdetéseik jelenhessenek meg rövid szöveges hirdetés, keresési eredmény, bannerek, egyéb más, a platform által biztosított formában a Google hirdetési hálózatain keresztül az azokat elérő felhasználók számára.
              </dd>

              <dt>
                <a href="https://marketingplatform.google.com/about/analytics/" target="_blank">Google Analytics</a>
              </dt>
              <dd>
                A Google Marketing Platform részekéntaz Analytics a Google elemző eszköze, amely abban segít a weboldalak és alkalmazások tulajdonosainak, hogy pontosabb képet kapjanak látogatóik tevékenységeiről. A szolgáltatás cookie-kat használhat, hogy információkat gyűjtsön és jelentést készítsen a weboldal használatára vonatkozó statisztikai adatokból anélkül, hogy egyénileg azonosítaná a látogatókat a Google számára.
              </dd>

              <dt>
                <a href="https://support.google.com/tagmanager/answer/6105160?hl=hu" target="_blank">Google Conversion Tag</a>
              </dt>
              <dd>
                A Google Ads konverziókövetési funkciója megmutatja, mi történik, miután egy ügyfél a hirdetésre kattint – vásárol-e valamilyen terméket, feliratkozik-e a hírlevélre, felhívja-e a vállalkozást, esetleg letölti-e az alkalmazást. Az értékesnek ítélt vagy az azt elősegítő ügyfélműveleteket konverziónak hívjuk.
              </dd>

              <dt>
                <a href="https://support.google.com/google-ads/answer/3124536?hl=hu" target="_blank">Google Dynamic Remarketing</a>
              </dt>
              <dd>
                A remarketing lehetővé teszi a hirdetések megjelenítését a felhasználók számára, illetve a hirdetéseknek a felhasználók által megtekintett tartalom alapján történő testreszabását. A Google Dynamic Remarketing eszköz segítségével ez tovább fejleszthető, és a webhely látogatói számára az általuk a webhelyen megtekintett konkrét termékkel kapcsolatos hirdetés is megjeleníthető.
              </dd>
            </dl>
            `
          )
        }
      },
      links: {
        data_policy: 'https://policies.google.com/technologies/partner-sites?hl=en',
        opt_out: 'http://www.youronlinechoices.com/hu/sugo',
        cookie_policy: [
          'https://support.google.com/ads/answer/2662922?hl=hu',
          'https://policies.google.com/technologies/cookies?hl=hu'
        ]
      }
    },
    {
      policy_group: 'facebook',
      provider: `Facebook <small>Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA</small> (Facebook Pixel)`,
      description: {
        i18n: {
          hu: (
            `
            <dl>
              <dt>
                <a href="https://www.facebook.com/business/help/742478679120153?helpref=page_content" target="_blank">Facebook</a>
              </dt>
              <dd>
                A Facebook Pixel egy analitikai eszköz,
                amellyel mérhető a hirdetési tevékenység
                hatékonysága, és megismerhetők a
                felhasználók által a honlapon végzett egyes
                interakciók.
                <br />
                A Facebook Pixel a honlap fejlécében kerül
                elhelyezésre. Ha valaki meglátogatja a
                honlapot és végrehajt egy a Facebook Pixel
                által figyelt műveletet (például vásárol
                valamit), akkor a Facebook Pixel aktiválódik és
                rögzíti a műveletet. Ezekhez az információkhoz
                köthetően a későbbiekben ezek a felhasználók
                a Facebook hirdetésekkel célzottan is
                elérhetővé válnak.
              </dd>
            </dl>
            `
          )
        }
      },
      links: {
        data_policy: 'https://www.facebook.com/about/privacy/update',
        opt_out: 'http://www.youronlinechoices.com/hu/sugo',
        cookie_policy: 'https://www.facebook.com/policies/cookies/'
      }
    }
  ],

  categories: [
    {
      type: 'essential',
      label: '{{i18n.cookie_essential_label}}',
      desc: '{{i18n.cookie_essential_desc}}',
      detail: '{{i18n.cookie_essential_detail}}',
      scriptTags: ['opt-in-essential'],

      cookies: [
        {
          pattern: ['PHPSESSID'],
          purpose: {
            i18n: {
              hu: 'Eltárolja a munkamenet azonosítót.',
              en: 'Preserves user session state across page requests.'
            }
          },
          domain: null,
          third_party: false,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: 'munkamenet',
              en: 'session'
            }
          },
          source: null,
          policy_group: null
        },


        {
          pattern: ['dwcconsent'],
          purpose: {
            i18n: {
              hu: 'A cookie beállításra kerül amikor a felhasználó elfogadja a cookie-k használatát.',
              en: 'This cookie belongs to the category of Strictly Necessary Cookies and is set to store the user consent to cookies.'
            }
          },
          domain: null,
          third_party: false,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '365 nap',
              en: '1 years'
            }
          },
          source: null,
          policy_group: null
        }
      ]
    },
    {
      type: 'functional',
      label: '{{i18n.cookie_functional_label}}',
      desc: '{{i18n.cookie_functional_desc}}',
      detail: '{{i18n.cookie_functional_detail}}',
      scriptTags: ['opt-in-functional'],
      cookies: [
        {
          pattern: 'lang',
          purpose: {
            i18n: {
              hu: 'Eltárolja a weboldalon kiválasztott nyelvet.',
              en: "This cookie tracks a user's language selection."
            }
          },
          domain: null,
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '5 év',
              en: '5 years'
            }
          },
          source: null,
          policy_group: 'null'
        },


        {
          pattern: 'SIDCC',
          purpose: {
            i18n: {
              hu: 'Egy biztonsági cookie mely védi a felhasználó adatait a jogosulatlan hozzáféréstől.',
              en: 'This is a security cookie to protect a user’s data from unauthorized access.'
            }
          },
          domain: ['.google.com', '.youtube.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '3 hónap',
              en: '3 months'
            }
          },
          source: null,
          policy_group: 'google'
        }
      ]
    },
    {
      type: 'performance',
      label: '{{i18n.cookie_performance_label}}',
      desc: '{{i18n.cookie_performance_desc}}',
      detail: '{{i18n.cookie_performance_detail}}',
      scriptTags: ['opt-in-performance'],
      cookies: [
        {
          pattern: ['HSID', 'LOGIN_INFO', 'SAPISID', 'SID', 'SSID', 'APISID'],
          purpose: {
            i18n: {
              hu: 'Ez a cookie beágyazott videók által kerül beállításra. Névtelen statisztikai adatok regisztrálásában vesz rész. Például, hogy hány alkalommal jelent meg egy videó és hogy milyen beállításokkal kerül lejátszásra. Szenzitív adat nem kerül összegyűjtésre mindaddig, míg be nem jelentkezik Google fiókjába, ebben az esetben az Ön választása összekapcsolásra kerül fiókjával, például, amikor rákattint a "Tetszik" gombra egy videó alatt.',
              en: 'These cookies are set via embedded YouTube videos. Register anonymous statistical data on, for example, how many times the video is displayed and the settings used for playback. No sensitive data is collected, unless you are logged in to your Google Account, in this case, your choices are associated with your account, such. Example, when you click "Like" on a video. For more information, see the Google guidelines. Third party cookie.'
            }
          },
          domain: ['.google.com', '.youtube.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '2 év',
              en: '2 years'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },

        {
          pattern: ['_gid', '_gat'],
          purpose: {
            i18n: {
              hu: 'Ez a cookie a google.com adatvédelmi nyilatkozata szerint gyűjti a weboldal statisztikáit és méri a konverziókat.',
              en: 'Used to gather website statistics, and track conversion rates.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '1 hónap',
              en: '1 month'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },
        {
          pattern: ['_ga'],
          purpose: {
            i18n: {
              hu: 'Ez a cookie a google.com adatvédelmi nyilatkozata szerint gyűjti a weboldal statisztikáit.',
              en: 'Used to gather website statistics.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '2 év',
              en: '2 year'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },
        {
          pattern: 'CONSENT',
          purpose: {
            i18n: {
              hu: 'Ez a cookie beágyazott videók által kerül beállításra. Névtelen statisztikai adatok regisztrálásában vesz rész. Például, hogy hány alkalommal jelent meg egy videó és hogy milyen beállításokkal kerül lejátszásra. Szenzitív adat nem kerül összegyűjtésre mindaddig, míg be nem jelentkezik Google fiókjába, ebben az esetben az Ön választása összekapcsolásra kerül fiókjával, például, amikor rákattint a "Tetszik" gombra egy videó alatt.',
              en: 'These cookies are set via embedded YouTube videos. Register anonymous statistical data on, for example, how many times the video is displayed and the settings used for playback. No sensitive data is collected, unless you are logged in to your Google Account, in this case, your choices are associated with your account, such. Example, when you click "Like" on a video. For more information, see the Google guidelines. Third party cookie.'
            }
          },
          domain: ['.google.com', '.youtube.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: 'állandó',
              en: 'persistent'
            }
          },
          source: 'Google',
          policy_group: 'google'
        }
      ]
    },
    {
      type: 'marketing',
      label: '{{i18n.cookie_marketing_label}}',
      desc: '{{i18n.cookie_marketing_desc}}',
      detail: '{{i18n.cookie_marketing_detail}}',
      scriptTags: ['opt-in-marketing'],
      onOptIn() {
        // console.log('opt-in-by settings');
      },
      onOptOut() {
        // console.log('opt-out-by settings');
      },
      cookies: [
        {
          pattern: ['_fbp'],
          purpose: {
            i18n: {
              hu: 'A Facebook használja különböző hirdetési termékeinek biztosításához, például valósidejű ajánlatok harmadik féltől származó hirdetőktől.',
              en: 'Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.'
            }
          },
          domain: null,
          third_party: true,
          type: 'HTTP',
          expiry: '3 hónap',
          source: 'Facebook Pixel',
          policy_group: 'facebook'
        },
        {
          pattern: ['fr'],
          purpose: {
            i18n: {
              hu: 'A Facebook használja különböző hirdetési termékeinek biztosításához, például valósidejű ajánlatok harmadik féltől származó hirdetőktől.',
              en: 'Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.'
            }
          },
          domain: '.facebook.com',
          third_party: true,
          type: 'HTTP',
          expiry: '3 hónap',
          source: 'facebook.com (*) Facebook Pixel',
          policy_group: 'facebook'
        },
        {
          pattern: ['tr'],
          purpose: {
            i18n: {
              hu: 'A Facebook használja különböző hirdetési termékeinek biztosításához, például valósidejű ajánlatok harmadik féltől származó hirdetőktől.',
              en: 'Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.'
            }
          },
          domain: '.facebook.com',
          third_party: true,
          type: 'Pixel',
          expiry: 'munkamenet',
          source: 'Facebook',
          policy_group: 'facebook'
        },
        {
          pattern: ['1P_JAR', '_gid', '_gat'],
          purpose: {
            i18n: {
              hu: 'Ez a cookie a google.com adatvédelmi nyilatkozata szerint gyűjti a weboldal statisztikáit és méri a konverziókat.',
              en: 'Used to gather website statistics, and track conversion rates.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '1 hónap',
              en: '1 month'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },

        {
          pattern: ['_ga'],
          purpose: {
            i18n: {
              hu: 'Ez a cookie a google.com adatvédelmi nyilatkozata szerint gyűjti a weboldal statisztikáit és méri a konverziókat.',
              en: 'Used to gather website statistics, and track conversion rates.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '2 év',
              en: '2 year'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },

        {
          pattern: 'ANID',
          purpose: {
            i18n: {
              hu: 'A Google használja ezt a cookie-t, hirdetési szolgáltatásaihoz, amelyet a böngésző a google.com domain alatt tárol.',
              de: 'Dies sind Cookies, die Google AdWords verwendet, um die Leistung der Werbung zu überwachen und Werbung für Besucher auf der Grundlage der zuvor besuchten Websites zu schalten.',
              en: "These are cookies that Google AdWords use to monitor performance of advertising and serve adverts to visitors based on the websites they've previously visited"
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '1 év',
              en: '1 year'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'NID',
          purpose: {
            i18n: {
              hu: 'Regisztrál egy egyedi azonosítót, amely azonosítja a visszatérő felhasználó eszközét. Az azonosítót a célzott hirdetésekhez használják.',
              en: "Registers a unique ID that identifies a returning user's device. The ID is used for targeted ads."
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '6 hónap',
              en: '6 months'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'OGPC',
          purpose: {
            i18n: {
              hu: 'Ezt a cookie-t a Google Maps szolgáltatás használja, hogy felhasználói preferenciákat és információkat tároljon.',
              en: 'These cookies are used by Google to store user preferences and information when viewing pages with Google maps on them.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '13 hónap',
              en: '13 months'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'PREF',
          purpose: {
            i18n: {
              hu: 'Regisztrál egy egyedi azonosítót a Google számára, hogy statisztikát tároljon, hogyan használják a Youtube videókat különböző oldalakon.',
              en: 'Register a unique ID that is used by Google to keep statistics on how the visitor uses YouTube videos to various sites.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '2 év',
              en: '2 year'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'S',
          purpose: {
            i18n: {
              hu: 'Az „S” cookie bizonyos szolgáltatásokat gyűjthet, amelyek segítenek a szolgáltatások javításában, beleértve a leggyakrabban látogatott oldalakat és azt, hogy a felhasználók bizonyos oldalakon hibaüzeneteket kapnak. Ez a cookie a PPC (pay per click) és a affiliate reklám hatékonyságának anonimikus mérésére is használható.',
              en: 'The “S” cookie may collect certain information used to help improve services, including the pages users visit most often and whether users get error messages from certain pages. This cookie may also be used to anonymously measure the effectiveness of PPC (pay per click) and affiliate advertising. '
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: 'munkamenet',
              en: 'session'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },

        {
          pattern: 'YSC',
          purpose: {
            i18n: {
              hu: 'Regisztrál egy egyedi azonosítót, hogy statisztikát tároljon azokról a Youtube videókról amit a felhasználó megtekintett.',
              en: 'Register a unique ID to keep statistics of YouTube videos that the user has seen.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: 'munkamenet',
              en: 'session'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'OTZ',
          purpose: {
            i18n: {
              hu: 'Az „OTZ” a Google Analytics által használt cookie, amely a webhelyek látogatóinak összesített elemzését teszi lehetővé.',
              en: '“OTZ” is a cookie used by Google Analytics that provides an aggregate analysis of Website visitors.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '1 hét',
              en: '1 week'
            }
          },
          source: 'Google',
          policy_group: 'google'
        }
      ]
    }

  ],

  template(that) {
    return (
      '<div class="dwcc-inner">' +
        '{{components.box_simple}}' +
        '{{components.box_advanced}}' +
        '{{components.box_cookies}}' +
      '</div>'
    );
  },
  components: {
    cat_essential: {
      type: 'category',
      defaultValue: 'accepted',
      label: '{{i18n.cookie_essential_label}}',
      desc: '{{i18n.cookie_essential_desc}}'
    },
    cat_statics: {
      type: 'category',
      defaultValue: 'accepted',
      label: '{{i18n.cookie_performance_label}}',
      desc: '{{i18n.cookie_performance_desc}}'
    },
    cat_marketing: {
      type: 'category',
      defaultValue: 'accepted',
      label: '{{i18n.cookie_marketing_label}}',
      desc: '{{i18n.cookie_marketing_desc}}'
    },
    btn_accept: {
      type: 'button',
      action: 'accept',
      text(component) {
        const dwcc = component.dwCookieConsent;
        if (dwcc.savedState) {
          return '{{i18n.keepTheSettingsAndClose}}';
        }
        return '{{i18n.btn_accept}}';
      }
    },
    btn_reject: {
      type: 'button',
      action: 'reject',
      text: '{{i18n.btn_reject}}'
    },
    btn_close: {
      type: 'button',
      action: function(component) {
        const dwcc = component.dwCookieConsent;
        dwcc.hide();
      },
      className: 'close',
      visible(component) {
        const dwcc = component.dwCookieConsent;
        return !!dwcc.savedState;
      },
      title: '{{i18n.btn_close_tile}}',
      text: '{{i18n.btn_close}}'
    },
    btn_privacy: {
      type: 'button',
      action: 'native',
      className: 'native link',
      inline: true,
      text: '{{i18n.btn_privacy}}',
      href: '{{i18n.dataPrivacyLink}}',
      target: '_blank'
    },
    btn_cookies: {
      type: 'button',
      className: 'link',
      action(component) {
        component.dwCookieConsent.getComponent('box_cookies').show('bysimple');
        component.dwCookieConsent.getComponent('box_simple').hide('bysimple');
      },
      text: '{{i18n.btn_cookies}}',
      inline: true
    },
    btn_advanced: {
      type: 'button',
      className: 'link',
      text: '{{i18n.btn_advanced}}',
      action(component) {
        component.dwCookieConsent.getComponent('box_advanced').show('bysimple');
        component.dwCookieConsent.getComponent('box_simple').hide('bysimple');
      }
    },
    btn_privacy_advanced: {
      type: 'button',
      action: 'native',
      className: 'block-link',
      inline: true,
      text: '{{i18n.btn_privacy_advanced}}',
      href: '{{i18n.dataPrivacyLink}}',
      target: '_blank'
    },
    btn_save_advanced: {
      type: 'button',
      text: '{{i18n.btn_save_advanced}}',
      action: 'accept'
    },
    btn_close_advanced: {
      type: 'button',
      action(component) {
        const dwcc = component.dwCookieConsent;
        const box_advanced = dwcc.getComponent('box_advanced');
        const box_simple = dwcc.getComponent('box_simple');
        // Close completely if the consent accepted and opened by a link.
        // if (!!dwcc.savedState && box_advanced.causes.show === 'bylink') {

        // Close completely if the consent accepted
        if (dwcc.savedState) {
          dwcc.hide();
        }
        else {
          box_advanced.hide('byadvanced');
          box_simple.show('byadvanced');
        }
      },
      className: 'close',
      title: '{{i18n.btn_close_tile}}',
      text: '{{i18n.btn_close}}'
    },
    btn_cookies_advanced: {
      type: 'button',
      inline: true,
      action(component) {
        component.dwCookieConsent.getComponent('box_cookies').show('byadvanced');
        component.dwCookieConsent.getComponent('box_advanced').hide('byadvanced');
      },
      className: 'block-link',
      // inline: true,
      text: '{{i18n.btn_cookies}}'
    },

    btn_close_cookies: {
      type: 'button',
      action(component) {
        const dwcc = component.dwCookieConsent;
        const box_cookies = dwcc.getComponent('box_cookies');
        // Close completely if the consent accepted and opened by a link.
        if (!!dwcc.savedState && box_cookies.causes.show === 'bylink') {
          dwcc.hide();
        }
        else if (box_cookies.causes.show === 'byadvanced') {
          component.dwCookieConsent.getComponent('box_cookies').hide('bycookies');
          component.dwCookieConsent.getComponent('box_advanced').show('bycookies');
        }
        else {
          component.dwCookieConsent.getComponent('box_cookies').hide('bycookies');
          component.dwCookieConsent.getComponent('box_simple').show('bycookies');
        }
      },
      className: 'close',
      title: '{{i18n.btn_close_tile}}',
      text: '{{i18n.btn_close}}'
    },

    rng_consent_degree: {

      init(component) {
        const dwcc = component.dwCookieConsent;
        const categories = dwcc.settings.categories;
        let html = '';
        this.max = categories.length;

        for (let i = 0; i < this.max; i++) {
          html += this.categoryHtml(categories[i], i);
        }

        this.value = this.max; // todo saved consent

        if (dwcc.savedState) {
          const savedCat = categories.find(c => c.type === dwcc.savedState);
          if (savedCat) {
            this.value = categories.indexOf(savedCat) + 1;
          }
        }

        this.content = `
          <div class="dwcc-rangeslider-categories" data-active="${this.value}">
            ${html}
          </div>
          <div class="dwcc-rangeslider-details">
            <div>${categories[this.value - 1].detail}</div>
            {{components.btn_cookies_advanced}}
            {{components.btn_privacy_advanced}}
          </div>
        `;
      },
      categoryHtml(category, x) {
        return (
          `<div class="dwcc-rangeslider-category" data-val="${x + 1}">
            <div class="dwcc-rangeslider-label">${category.label}</div>
            <p>${category.desc}</p>
          </div>`
        );
      },
      events: {
        '.dwcc-rangeslider-category > *': {
          'click touchstart': function(e, elem, componentData) {
            const val = elem.parentNode.getAttribute('data-val');
            this.componentData.updateValue(this, val);
          }
        },
        'input.dwcc-rangeslider': {
          'change': function(e, elem) { this.componentData.updateValue(this); e.preventDefault(); },
          'input': function(e, elem) { this.componentData.updateValue(this); }
        }
      },
      updateValue(component, _newValue) {
        let el_input = component.elComponent.querySelector('input');
        if (typeof _newValue === 'string') {
          el_input.value = _newValue;
        }
        this.value = el_input.value;
        const categories = component.dwCookieConsent.settings.categories;
        const el_details = component.elComponent.querySelector('.dwcc-rangeslider-details div');
        const el_cat = component.elComponent.querySelector('.dwcc-rangeslider-categories');
        el_cat.setAttribute('data-active', this.value);
        el_details.innerHTML = component.renderer(
          categories[this.value - 1].detail
        );
      },
      type: 'rangeslider',
      value: 1,
      step: 1,
      min: 1,
      max: null,
      content: ''
    },
    box_simple: {
      type: 'box',
      // <small>{{components.btn_privacy}}</small> |

      content: (`
        {{components.btn_close}}
        <div class="dwcc-disclaimer-text">
          <h1>{{i18n.simpleHeadline}}</h1>
          <p>
            {{i18n.simpleDisclaimer}}
            &nbsp;
            <small>{{components.btn_cookies}}</small>
          </p>
        </div>
        <div class="dwcc-buttons dwcc-inline-childs">
          {{components.btn_accept}}
          {{components.btn_advanced}}
        </div>
        `
      )
    },
    box_advanced: {
      type: 'box',
      visible: false,
      events: {
        '{document}': {
          'keydown': function(e, elem) {
            if (this.visible && ((e.key + '').indexOf('Esc') > -1 || e.keyCode === 27)) {
              this.dwCookieConsent.getComponent('btn_close_advanced').callAction();
              e.stopPropagation();
            }
          }
        }
      },
      content: (
        '{{components.btn_close_advanced}}' +
        '<div class="dwcc-disclaimer-text">' +
          '<h1>{{i18n.advancedHeadline}}</h1>' +
          '<p>{{i18n.advancedDisclaimer}}</p>' +
        '</div>' +
        '<div class="dwcc-categories">' +
          '{{components.rng_consent_degree}}' +
        '</div>' +
        '{{components.btn_save_advanced}}'

      )
      // content() {
      //   return (
      //     '{{components.cat_essential}}' +
      //     '{{components.cat_statics}}' +
      //     '{{components.cat_marketing}}' +
      //   );
      // }
    },
    box_cookies: {
      type: 'box',
      visible: false,
      events: {
        '{document}': {
          'keydown': function(e, elem) {
            if (this.visible && ((e.key + '').indexOf('Esc') > -1 || e.keyCode === 27)) {
              this.dwCookieConsent.getComponent('btn_close_cookies').callAction();
              e.stopPropagation();
            }
          }
        },
        '{this}': {
          'click touch': function(e, elem) {
            if (e.target === elem) {
              this.dwCookieConsent.getComponent('btn_close_cookies').callAction();
            }
          }
        }
      },
      content: (
        '{{components.btn_close_cookies}}' +
        '<div class="dwcc-cookies-text">' +
          '{{i18n.cookiePolicy}}' +
        '</div>'
      )
    },
    cookie_table_cookies_essential: {
      type: 'cookie_table',
      content(component) {
        const dwcc = component.dwCookieConsent;
        const cat = dwcc.settings.categories.find(c => c.type === "essential");
        return cat ? cat : 'Category not found (essential)';
      }
    },
    cookie_table_cookies_functional: {
      type: 'cookie_table',
      content(component) {
        const dwcc = component.dwCookieConsent;
        const cat = dwcc.settings.categories.find(c => c.type === "functional");
        return cat ? cat : 'Category not found (functional)';
      }
    },
    cookie_table_cookies_performance: {
      type: 'cookie_table',
      content(component) {
        const dwcc = component.dwCookieConsent;
        const cat = dwcc.settings.categories.find(c => c.type === "performance");
        return cat ? cat : 'Category not found (performance)';
      }
    },
    cookie_table_cookies_marketing: {
      type: 'cookie_table',
      content(component) {
        const dwcc = component.dwCookieConsent;
        const cat = dwcc.settings.categories.find(c => c.type === "marketing");
        return cat ? cat : 'Category not found (marketing)';
      }
    },
    thirdparty_table: {
      type: 'policy_group_table'
    }
  },
  componentTypes: {
    category: {
      defaults: {
        label: '',
        desc: '',
        defaultValue: 'declined',
        extraClass: 'dwcc-clear'
      },
      extend(componentData) {

      },
      updateState() {
        const el = this.elComponent.querySelector("input[type=radio]:checked");
        this.state.value = el.value;
      },
      events: {
        '.switch-input': {
          'change': function(e, elem) { this.updateState(); }
        }
      },
      template: (
        //  @credit: https://codepen.io/oggyindahouse/pen/Bamui
        '<strong class="dwcc-cat-label">{{component.label}}</strong>' +
        '<div class="switch switch-white">' +
            '<input type="radio" class="switch-input" name="{{component.cid}}" value="accepted" id="{{component.cid}}-on" checked>' +
            '<label for="{{component.cid}}-on" class="switch-label switch-label-off">{{i18n.cat_on}}</label>' +
            '<input type="radio" class="switch-input" name="{{component.cid}}" value="declined" id="{{component.cid}}-off">' +
            '<label for="{{component.cid}}-off" class="switch-label switch-label-on">{{i18n.cat_off}}</label>' +
            '<span class="switch-selection"></span>' +
        '</div>'
      )
    },
    button: {
      defaults: {
        title: '',
        text: '',
        className: 'button',
        href: false,
        target: false
      },
      extend(componentData) {
        if (typeof componentData.text === 'function') {
          componentData.text = componentData.text(this);
        }
      },
      events: {
        '.dwcc-btn:not(.dwcc-native)': {
          'click touchstart': function(e, elem, componentData) {
            e.stopPropagation(); e.preventDefault();
            this.callAction();
          }
        }
      },
      template() {
        const data = this.componentData;
        const classes = [
          'dwcc-btn',
          'dwcc-{{component.className}}',
          'dwcc-{{component.cid}}'
        ];
        if (data.action === 'native') {
          classes.push('dwcc-native');
        }
        return (
          `<a
            ${data.href ? ' href="{{component.href}}"' : ''}
            ${data.target ? ' target="{{component.target}}"' : ''}
            class="${classes.join(' ')}"
            title="{{component.title}}">{{component.text}}
          </a>`
        );
      }
    },
    box: {
      template: (
        '<div class="dwcc-box dwcc-{{component.cid}}">{{component.content}}</div>'
      )
    },
    cookie_table: {
      extend() {
        const data = this.componentData;
        const dwcc = this.dwCookieConsent;
        const map = dwcc.settings.cookieTableMap;
        if (typeof data.content !== 'function') {
          return;
        }
        const category = data.content(this);
        if (!category || typeof category !== 'object') { return; }

        data.headCells = [];
        data.bodyRows = [];

        category.cookies.forEach((cookie, i) => {
          const row = [];
          for (const prop in map) {
            if (!map.hasOwnProperty(prop)) { continue; }
            if (i === 0) {
              // building header cells in the first cycle
              data.headCells.push({ prop: prop, content: map[prop], hl: true });
            }
            let val = cookie[prop];
            if (typeof val === 'object' && val && val.i18n) {
              val = val.i18n[dwcc.settings.lang] || val.i18n['en'] || '';
            }
            if ((prop === 'domain' || prop === 'source') && val === null) {
              val = window.location.hostname;
            }
            else if (prop === 'third_party') {
              val = val ? '●' : '';
            }
            if (Array.isArray(val)) {
              val = val.join(', ');
            }
            row.push({ prop: prop, content: val });
          }
          data.bodyRows.push(row);
        });
      },
      tpl_tr(cells, hl) {
        return `<tr>${cells.map(c => this.tpl_cell(c, hl)).join('')}</tr>`;
      },
      tpl_cell(cell, hl) {
        const tag = (hl || cell.hl) ? 'th' : 'td';
        return `<${tag} class="${cell.prop}">${cell.content}</${tag}>`;
      },
      template() {
        const data = this.componentData;
        let out;

        if (data.bodyRows.length) {
          out = (
            `<table>
                <thead>${this.tpl_tr(data.headCells, true)}</thead>
                <tbody>${data.bodyRows.map(r => this.tpl_tr(r)).join('')}</tbody>
            </table>
            `
          );
        }
        else {
          out = (
            `<p class="dwcc-cookie-table-empty-category"><i>{{i18n.table_empty_category}}</i></p>`
          );
        }
        return (
          `<div class="dwcc-table dwcc-cookie-table dwcc-{{component.cid}}">
            ${out}
          </div>`
        );
      }
    },
    policy_group_table: {
      extend() {
        const data = this.componentData;
        const dwcc = this.dwCookieConsent;
        const map = dwcc.settings.policyGroupTableMap;
        const groups = dwcc.settings.policyGroups;

        if (!groups || typeof groups !== 'object') { return; }

        data.headCells = [];
        data.bodyRows = [];
        let subrow = null;

        groups_loop:for (let i = 0; i < groups.length; i++) {
          const group = groups[i];
          const row = [];
          for (const prop in map) {
            if (!map.hasOwnProperty(prop)) { continue; }
            if (i === 0) {
              // building header cells in the first cycle
              let c = map[prop];
              if (c && typeof c === 'object' && c.content) { c = c.content; }
              if (c !== null && c !== false) {
                data.headCells.push({ prop: prop, content: c, hl: true });
              }
            }
            let val = group[prop];

            if (typeof val === 'object' && val && val.i18n) {
              val = val.i18n[dwcc.settings.lang] || val.i18n['en'] || '';
            }

            if (Array.isArray(val)) {
              val = val.join(', ');
            }
            if (prop === 'links') {
              val = this.tpl_links(val, map[prop]);
            }
            if (prop === 'policy_group') {
              const cookies = this.getCoociesByGroup(val);
              if (cookies.length) {
                val = cookies.join(', ');
              }
              else {
                continue groups_loop;
              }
            }
            if (prop === 'description') {
              if (val) {
                subrow = { prop, content: val };
              }
              continue;
            }
            row.push({ prop: prop, content: val });

          }
          data.bodyRows.push(row);

          if (subrow) {
            subrow.colSpan = data.headCells.length;
            data.bodyRows.push([subrow]);
            if (i + 1 < groups.length) {
              data.bodyRows.push(data.headCells);
            }
          }
          subrow = null;
        }
        if (data.bodyRows[data.bodyRows.length - 1] === data.headCells) {
          data.bodyRows.pop();
        }
      },
      getCoociesByGroup(group) {
        const found = [];
        const categories = this.dwCookieConsent.settings.categories;

        categories.forEach(category => category.cookies.forEach(c => {
          if (c.policy_group === group) {
            found.push(
              Array.isArray(c.pattern) ? c.pattern.join(', ') : c.pattern
            );
          }
        }));
        return found;
      },
      tpl_links(links, texts) {
        let out = [];
        for (let p in links) {
          if (links.hasOwnProperty(p)) {
            (Array.isArray(links[p]) ? links[p] : [links[p]]).forEach(
              url => out.push(
                `<a target="_blank" href="${url}">${texts[p] || 'link'}</a>`
              )
            );
          }
        }
        return out.join('<br />');
      },
      tpl_tr(cells, hl) {
        return `<tr>${cells.map(c => this.tpl_cell(c, hl)).join('')}</tr>`;
      },
      tpl_cell(cell, hl) {
        const tag = (hl || cell.hl) ? 'th' : 'td';
        const collSpan = cell.colSpan ? ` colSpan="${cell.colSpan}"` : '';
        return `<${tag} ${collSpan}class="${cell.prop}">${cell.content}</${tag}>`;
      },
      template() {
        const data = this.componentData;
        const out = (
          `<div class="dwcc-table dwcc-cookie-table dwcc-{{component.cid}}">
            <table>
              <thead>${this.tpl_tr(data.headCells, true)}</thead>
              <tbody>${data.bodyRows.map(r => this.tpl_tr(r)).join('')}</tbody>
            </table>
          </div>`
        );
        return out;
      }
    },
    rangeslider: {
      template(a) {
        return (`
          <div class="dwcc-rangeslider-wrp vertical dwcc-{{component.cid}}">
            <input type="range" class="dwcc-rangeslider"
              value="{{component.value}}" step="{{component.step}}"
              min="{{component.min}}" max="{{component.max}}"
              />
          </div>
          {{component.content}}
        `);
      }
    }
  },
  onOpen(mode) {
    if (mode === 'cookies') {
      this.getComponent('box_simple').hide('bylink');
      this.getComponent('box_advanced').hide('bylink');
      this.getComponent('box_cookies').show('bylink');
    }
    else if (mode === 'advanced') {
      this.getComponent('box_simple').hide('bylink');
      this.getComponent('box_advanced').show('bylink');
      this.getComponent('box_cookies').hide('bylink');
    }
    else {
      this.getComponent('box_simple').show('bylink');
      this.getComponent('box_advanced').hide('bylink');
      this.getComponent('box_cookies').hide('bylink');
    }
  },
  onShow() {

  },
  onHide() {
    this.getComponent('box_simple').hide('byhiding');
    this.getComponent('box_advanced').hide('byhiding');
    this.getComponent('box_cookies').hide('byhiding');
  },
  onReady() {
    if (this.savedCategory) {
      this.settings._handleRestricted.call(
        this, this.settings.categories, this.savedCategory
      );
    }
  },
  _handleRestricted(categories, category) {
    if (!category) { void (console && console.log('Invalid category')); return; }
    const currentI = category === false ? -1 : categories.indexOf(category);
    const allowedLevels = categories.slice(0, currentI + 1).map(c => c.type);

    document.querySelectorAll('.dwcc-restricted_3rdparty-content').forEach(el => {
      const level = el.getAttribute('data-dwcc-level');

      if (level && allowedLevels.indexOf(level) > -1) {
        if (el.classList.contains('do-reload')) {
          window.location.reload();
        }
        else if (el.classList.contains('do-remove')) {
          el.parentNode.removeChild(el);
        }
      }
    });

    document.querySelectorAll('.dwcc-restricted_3rdparty-content-hidden').forEach(el => {
      const level = el.getAttribute('data-dwcc-level');
      if (level && allowedLevels.indexOf(level) > -1) {
        el.classList.remove('dwcc-restricted_3rdparty-content-hidden');
      }
    });
  },

  onAccepted(category, reloadInProgress) {
    this.settings._handleRestricted.call(this, this.settings.categories, category);

    if (reloadInProgress) { return; }

    if (document.querySelector('.dwcc-restricted_3rdparty-content')) {
      window.location.reload();
    }

    document.querySelectorAll('iframe').forEach(el => {
      let param = (el.src || '').match(/[&?]cookies?=[^&?#$]+/i);
      if (!param || !param[0]) { return; }

      param = param[0];
      let pa_ram = param.split('=');
      let newparam = pa_ram[0] + '=';

      if (pa_ram[1] === '0' || pa_ram[1] === '1') {
        // the prevoius value is likely numeric boolean
        newparam += (category.type === 'marketing') ? '1' : '0';
      }
      else {
        newparam += category.type;
      }
      el.src = el.src.replace(param, newparam);
    });
  },
  detectSoftOptIn(done) {
    const box_advanced = this.getComponent('box_advanced');
    const notSelector = `.dwcc-cookieconsent *, ${this.settings.editConsentSelector}`;
    const clickables = 'a, button, :button';
    if (typeof window.jQuery !== "undefined") {
      const $ = window.jQuery;
      let $clickable = $(clickables).not(notSelector);
      let $focusable = $(':input').filter(':text, select, :radio, :checkbox').not(notSelector);

      const onSoftEvent = function(e) {
        if (e.originalEvent && !box_advanced.visible) {
          done();
          $clickable.off('click', onSoftEvent);
          $focusable.off('focus', onSoftEvent);
        }
      };
      $clickable.one('click', onSoftEvent);
      $focusable.one('focus', onSoftEvent);
    }
  },
  actions: {
    accept(component) {
      const dwcc = component.dwCookieConsent;
      const rngComponent = dwcc.getComponent('rng_consent_degree');
      const value = rngComponent.componentData.value + '';
      const category = dwcc.settings.categories[value - 1];
      if (!category || !category.type) {
        throw new Error('unexpected error during validation of range-slider value.');
      }
      dwcc.accept(category.type);
    },
    reject(component) {
      const dwcc = component.dwCookieConsent;
      dwcc.reject();
    },
    openlink(component) {
      if (this.href) {
        window.open(this.href, this.target);
      }
    }
  }
};
